import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { AddressListModule } from 'src/components/address-list/address-list.module'
import { BreadcrumbsModule } from 'src/components/breadcrumbs/v2/breadcrumbs.module'
import { StickyHeaderModule } from 'src/directives/sticky-header/sticky-header.module'
import { SelectSearchModule } from '../../../../components/select-search/select-search.module'
import { BuyersGroupsPageComponent } from './buyers-groups-page.component'
import { BuyersGroupsRoutingModule } from './buyers-groups-page-routing.module'
import { BuyersGroupsItemComponent } from './buyers-groups-item/buyers-groups-item.component'
import { MatChipsModule } from '@angular/material/chips'
import { MatExpansionModule } from '@angular/material/expansion'
import { AddBuyersGroupComponent } from './add-buyers-group/add-buyers-group.component'
import { BuyersGroupApiService } from './buyers-groups-item/buyers-groups.service'

@NgModule({
  declarations: [
    BuyersGroupsPageComponent,
    BuyersGroupsItemComponent,
    AddBuyersGroupComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatDialogModule,
    BuyersGroupsRoutingModule,
    BreadcrumbsModule,
    AddressListModule,
    FlexLayoutModule,
    SelectSearchModule,
    StickyHeaderModule,
    MatChipsModule,
    MatExpansionModule,
  ],
  providers: [BuyersGroupApiService],
})
export class BuyersGroupsModule {}
