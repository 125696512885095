<mat-expansion-panel style="margin: 10px; border-radius: 5px;" [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title [formGroup]="itemFormGroup">
      <span *ngIf="!isEditable" style="text-transform: capitalize">{{itemFormGroup.value.name}}</span>
      <input class="editable" *ngIf="isEditable" matInput formControlName="name" (keydown)="setEditable($event)"
        (keyup)="setEditable($event)" (click)="setEditable($event)">
      <button *ngIf="!isEditable" mat-icon-button type="button" (click)="setEditable($event)">
        <mat-icon class="fa fa-edit"></mat-icon>
      </button>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <tc-select-search placeholder="Select Buyers" [multiple]="true" [group]="itemFormGroup" ctrlName="buyers"
      [items]="buyers" bindValue="id" bindLabel="name">
    </tc-select-search>

    <mat-chip-listbox>
      <mat-chip-option selected color="{{getBuyerColor(buyer)}}" *ngFor="let buyer of itemFormGroup.value.buyers" [removable]="true"
        (removed)="onBuyerRemoved(buyer)" (keydown.enter)="onBuyerRemoved(buyer)">
        {{ buyersMap[buyer]?.name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </ng-template>
  <mat-action-row>
    <button mat-raised-button type="button" (click)="deleteGroup()">
      <mat-icon class="fa fa-trash-can"></mat-icon> Delete
    </button>
    <button [disabled]="!dirty || itemFormGroup.invalid" mat-raised-button type="button" (click)="updateGroup()">
      <mat-icon class="fa fa-save"></mat-icon> Save
    </button>
  </mat-action-row>
</mat-expansion-panel>
