<h2 mat-dialog-title>
  <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp; Add Buyers Group
</h2>
<form [formGroup]="formGroup" (submit)="save()">
  <mat-dialog-content gdColumns="repeat(1, minmax(0, 1fr))" gdGap="0 15px">
    <mat-form-field>
      <mat-label>Group Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
    <tc-select-search placeholder="Select Buyer Company Name" [multiple]="true" [group]="formGroup" ctrlName="buyers"
      [items]="buyers" bindValue="id" bindLabel="name"></tc-select-search>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Close</button>
    <button mat-raised-button type="submit" color="primary">
      <i class="fa fa-fw fa-save" aria-hidden="true"></i>
      Save
    </button>
  </mat-dialog-actions>
</form>
