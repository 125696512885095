import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed'
import { BuyersGroup } from './buyers-groups.service'
import { DeepReadonly } from '@tradecafe/types/utils'
import { ACCOUNT_ACTIVE, AccountObject } from '@tradecafe/types/core'

@Component({
  selector: 'tc-buyers-groups-item',
  templateUrl: './buyers-groups-item.component.html',
  styleUrls: ['./buyers-groups-item.component.scss'],
})
export class BuyersGroupsItemComponent extends OnDestroyMixin
  implements OnInit {
  @Input() buyersMap: Dictionary<DeepReadonly<AccountObject>>
  @Input() buyers: DeepReadonly<AccountObject>[]
  @Input() group: BuyersGroup
  @Output() onDeleteGroup: EventEmitter<BuyersGroup> = new EventEmitter()
  @Output() onUpdateGroup: EventEmitter<{
    group: BuyersGroup
    callback: Function
  }> = new EventEmitter()
  itemFormGroup: FormGroup
  dirty: boolean
  isEditable: boolean

  constructor(private formBuilder: FormBuilder) {
    super()
    this.itemFormGroup = this.formBuilder.group({
      group_id: [null, Validators.required],
      buyers: [null, Validators.required],
      name: [null, Validators.required],
    })
  }


  ngOnInit(): void {
    this.itemFormGroup.patchValue(this.group)
    this.itemFormGroup.valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => (this.dirty = true))
  }

  onBuyerRemoved(removedBuyer) {
    const buyers = this.itemFormGroup.value.buyers
    this.itemFormGroup.patchValue({
      buyers: buyers.filter((buyer) => buyer !== removedBuyer),
    })
  }

  updateGroup() {
    if (this.itemFormGroup.valid) {
      this.onUpdateGroup.emit({
        group: this.itemFormGroup.value,
        callback: () => {
          this.dirty = false
          this.isEditable = false
        },
      })
    }
  }
  deleteGroup() {
    this.onDeleteGroup.emit(this.group)
  }

  setEditable(event: Event) {
    this.isEditable = true
    event.stopPropagation()
  }

  getBuyerColor(buyer) {
    return this.buyersMap[buyer].status === ACCOUNT_ACTIVE ? 'primary': 'warn'
  }
}
